<template>
  <b-container
    :class="['overlay-bg', 'wall']"
    :style="containerStyle"
    id="Smooth-header"
    fluid
  >
    <div class="d-flex justify-content-center my-1">
      <SocialSharing
        static
        background="#00000000"
        twitter="padcmoi"
        gitlab="juliennaskot"
        discord="257rUb9"
        linkedin="julienjean-nice"
        style="font-size: 1.3em"
        class="text__shadow"
      />
    </div>

    <b-img-lazy
      :src="this['view_home/identity']"
      alt="identity"
      class="main_picture my-2 box__shadow"
      center
      thumbnail
      rounded
      fluid
    />

    <span class="mt-3 d-flex justify-content-center">
      <h5 class="font_bungee wordsCarouselExtends">
        <WordCarouselFade
          tag="b-badge"
          :words="view_home.smallScreenWordsCarouselExtends"
        />
      </h5>
    </span>

    <h5
      class="text-center font_bungee wordsCarouselMain text__shadow mt-3 mb-2"
    >
      <WordCarouselFade tag="strong" :words="view_home.wordsCarouselMain" />
    </h5>

    <div class="CV_infoContainer">
      <div class="FlashText font_bungee" @click="goToCv()">
        <i class="far fa-file-pdf"></i>&nbsp;CV.PDF
      </div>
    </div>
    <div class="MoreInfo_infoContainer">
      <div class="FlashText font_bungee" @click="$router.push('/more-info')">
        <i class="fas fa-info-circle"></i>&nbsp;En savoir plus
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    WordCarousel: () => import("@/components/WordCarousel"),
    WordCarouselFade: () => import("@/components/WordCarouselFade"),
    SocialSharing: () => import("@/components/SocialSharing"),
  },
  computed: {
    ...mapState(["commons", "events", "view_home"]),
    ...mapGetters(["view_home/identity"]),
    vuefluxStyle() {
      return {
        height: this.events.height + "px",
      };
    },
    containerStyle() {
      return {
        overflow: "hidden",
        width: this.events.width + "px",
        height: this.events.height + "px",
        padding: 0,
        margin: 0,
        "margin-bottom": "-56px",
      };
    },
  },
  methods: {
    goToCv() {
      window.location.href = "https://api.naskot.fr/api.php/go/to/url?qr=53381a3cdccbfb8fe60b4149b20aef2e";
    },
  },
};
</script>

<style scoped>
.blinking {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #000;
  }
  49% {
    color: #000;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}
.text_container {
  background: #163a58dd;
}
.text__shadow {
  text-shadow: 1px 1px 2px #333, 5px 5px 20px black;
  /* text-shadow: 1px 1px 2px #333, 5px 5px 20px black, 0 0 5px black; */
}
.box__shadow {
  box-shadow: 1px 1px 2px #333, 5px 5px 20px black;
  /* box-shadow: 1px 1px 2px #333, 5px 5px 20px black, 0 0 5px black; */
}
.letter_height {
  -webkit-transform: scaleY(1.5);
  -moz-transform: scaleY(1.5);
  -o-transform: scaleY(1.5);
  transform: scaleY(1.5);
}
.main_picture {
  width: 150px;
  border-radius: 50%;
  transition: 0.2s;
  background: conic-gradient(
    #ffc107,
    transparent,
    #ffc107,
    transparent,
    #ffc107,
    transparent,
    #ffc107,
    transparent,
    #ffc107
  );
  border: 0.75em solid #ffc107aa;
}

.font_bungee {
  font-family: "Bungee", cursive;
}

@-webkit-keyframes catchAttention {
  5% {
    background: #ffc107;
    padding: 0 10px;
    margin: 0;
    color: black;
    margin-left: 1em;
  }
}
@keyframes catchAttention {
  5% {
    background: #ffc107;
    padding: 0 10px;
    margin: 0;
    color: black;
    margin-left: 1em;
  }
}
.CV_infoContainer {
  position: absolute;
  bottom: 56px;
  left: 0;
}
.CV_infoContainer .FlashText {
  background: transparent;
  color: white;
  animation-name: catchAttention;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 5px;
  font-size: 1.3em;
}
.CV_infoContainer .FlashText:hover {
  background: #ffc107;
  padding: 0 10px;
  color: black;
  margin-left: 0;
  transition: all 0.5s;
  animation: none;
}

.MoreInfo_infoContainer {
  position: absolute;
  bottom: 56px;
  right: 0;
}
.MoreInfo_infoContainer .FlashText {
  background: transparent;
  color: white;
  animation-name: catchAttention;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 5px;
  font-size: 1.3em;
}
.MoreInfo_infoContainer .FlashText:hover {
  background: #ffc107;
  padding: 0 10px;
  color: black;
  margin-left: 0;
  transition: all 0.5s;
  animation: none;
}

.fa-html5 {
  color: #e94e2f;
}
.fa-css3-alt {
  color: #0f76b9;
}
.fa-js {
  color: #dab92c;
}
.fa-bootstrap {
  color: #563d7c;
}
.fa-php {
  color: #8a93bd;
}
.fa-node-js {
  color: #83cd29;
}
.fa-vuejs {
  color: #41b883;
}
/*
 *************************  Media Queries *************************
  color: gestion de la couleur (en bits/pixel).
  height: hauteur de la zone d'affichage (fenêtre).
  width: largeur de la zone d'affichage (fenêtre).
  device-height: hauteur du périphérique.
  device-width: largeur du périphérique.
  orientation: orientation du périphérique (portrait ou paysage).
  media: type d'écran de sortie. Quelques-unes des valeurs possibles :
    screen: écran « classique » ;
    handheld: périphérique mobile ;
    print: impression ;
    tv: télévision ;
    projection: projecteur ;
    all: tous les types d'écran.
*/

@media screen and (min-width: 0px) and (max-width: 576px) {
  .wordsCarouselMain {
    font-size: 5vw;
  }
  .wordsCarouselExtends {
    font-size: 5vw;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .wordsCarouselMain {
    font-size: 5vw;
  }
  .wordsCarouselExtends {
    font-size: 5vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .wordsCarouselMain {
    font-size: 4vw;
  }
  .wordsCarouselExtends {
    font-size: 4vw;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .wordsCarouselMain {
    font-size: 3vw;
  }
  .wordsCarouselExtends {
    font-size: 3vw;
  }
}
@media screen and (min-width: 1200px) {
}
</style>
